
require('./bootstrap');
import Splide from '@splidejs/splide';


let modals = document.querySelectorAll('.modals-video');
if (modals.length > 0) {
    document.addEventListener('click', function () {
        let videos = document.querySelectorAll('.the-video');
        videos.forEach((video) => {
            let video_src = video.getAttribute('src');
            video.setAttribute('src', '');
            video.setAttribute('src', video_src);
        });

    });

}


/* Inscription validation training */
let send_app = document.getElementById('send_application');
if (send_app) {
    send_app.addEventListener('click', function (event) {

        let form = document.getElementById('formTraining')
        if (form) {
            form.addEventListener('submit', event => {
                // Get the email and confirm email fields
                let emailInput = form.querySelector('#email');
                let confirmEmailInput = form.querySelector('#confirm_mail');

                if ((!form.checkValidity() || (emailInput.value !== confirmEmailInput.value))) {
                    event.preventDefault()
                    event.stopPropagation()

                    let setConfirmEmailValidity = () => {
                        if (emailInput.value !== confirmEmailInput.value) {
                            confirmEmailInput.setCustomValidity("Email addresses must match");
                            form.reportValidity();
                        } else {
                            confirmEmailInput.setCustomValidity("");
                        }
                    }

                    // Add an input event listener to both inputs to check their validity on every input
                    emailInput.addEventListener('input', setConfirmEmailValidity);
                    confirmEmailInput.addEventListener('input', setConfirmEmailValidity);

                }

                if (form.checkValidity()) {
                    sessionStorage.setItem('success_message', 'validated');

                }
                form.classList.add('was-validated')
            }, false)


        }

    })
}

/* Me rappeler validation*/
let send_demand_me_rappeler = document.getElementById('send_demand_me_rappeler');
if (send_demand_me_rappeler) {
    send_demand_me_rappeler.addEventListener('click', function (event) {

        let form = document.getElementById('formMeRappeler')
        if (form) {
            form.addEventListener('submit', event => {
                if ((!form.checkValidity())) {
                    event.preventDefault()
                    event.stopPropagation()

                } else {
                    sessionStorage.setItem('success_me_rappeler', 'validated inscription');

                }
                form.classList.add('was-validated')
            }, false)

        }
    })
}


document.addEventListener('DOMContentLoaded', function () {

    /* modal confirmed inscription formation   */
    if (sessionStorage.getItem('success_message')) {
        let to_modal = document.getElementById('clicktoModal');
        to_modal.click();
        // Remove the success message from session storage
        sessionStorage.removeItem('success_message');
    }


    /*modal confirmed  me Rappeler */
    if (sessionStorage.getItem('success_me_rappeler')) {
        let to_modal = document.getElementById('modal_confirmed_me_rappeler');
        to_modal.click();
        sessionStorage.removeItem('success_me_rappeler');
    }



    var direction_page = document.dir;
    // Navbar fixed on scroll (sticky top)
    window.addEventListener('scroll', function () {
        if (window.scrollY > 200) {
            document.getElementById('main-navbar').classList.add('fixed-top');
            // add padding top to show content behind navbar
            let navbar_height = document.querySelector('.navbar').offsetHeight;
            document.body.style.paddingTop = navbar_height + 'px';
        } else {
            document.getElementById('main-navbar').classList.remove('fixed-top');
            // remove padding top from body
            document.body.style.paddingTop = '0';
        }
    });


    // Home page product slider
    const homeProductsSlider = document.getElementById('home-products-slider');
    if (typeof (homeProductsSlider) != 'undefined' && homeProductsSlider != null) {
        const homeSplide = new Splide('#home-products-slider', {
            type: 'loop',
            perPage: 4,
            perMove: 1,
            gap: 0,
            focus: '-1',
            trimSpace: false,
            pagination: true,
            breakpoints: {
                900: {
                    perPage: 1,
                },
                1200: {
                    perPage: 3,
                },
            }
        });
        homeSplide.mount();
    }

    // Home page testimonials slider
    const homeTestimonialsSlider = document.getElementById('home-testimonials-slider');
    if (typeof (homeTestimonialsSlider) != 'undefined' && homeTestimonialsSlider != null) {
        let TestimonialsSplide = new Splide('#home-testimonials-slider', {
            type: 'loop',
            perPage: 1,
            perMove: 1,
            gap: 0,
            trimSpace: false,
        });
        TestimonialsSplide.mount();
    }


    // product packs slider
    const packsSlider = document.getElementById('product-packs-slider');
    if (typeof (packsSlider) != 'undefined' && packsSlider != null) {
        const PacksSplide = new Splide('#product-packs-slider', {
            type: 'loop',
            perPage: 3,
            perMove: 1,
            gap: 0,
            focus: 'center',
            trimSpace: false,
            pagination: true,
            direction: direction_page,
            breakpoints: {
                700: {
                    perPage: 1,
                },
                900: {
                    perPage: 2,
                    focus: 'left',
                    pagination: false,
                },
                1200: {
                    perPage: 3,
                },
            }
        });
        PacksSplide.mount();
    }

});



// Mini Search
const queryInput = document.getElementById("search-input");
const spinner = document.getElementById("spinner");
const searchResults = document.getElementById("search-results");

const fetchQueryResults = (query = queryInput.value,) => {
    let searchBaseUrl = '/';
    if (currentLang == 'ar') {
        searchBaseUrl = '/ar/';
    }
    const url = searchBaseUrl + 'recherche?search=' + queryInput.value;

    // Clear our current results.
    searchResults.innerHTML = "";

    // Stop here if the query is empty.
    if (!query.length) {
        return false;
    }

    // Add spinner.
    spinner.classList.replace("invisible", "visible");

    // Fetch new results.
    axios
        .get(url, { timeout: 6000 })
        .then(response => {
            searchResults.innerHTML = response.data
        })
        .catch(error => {
            console.error(error);
        })
        .finally(() => {
            spinner.classList.replace("visible", "invisible");
        });
};

const debounce = _.debounce(fetchQueryResults, 300);

queryInput.addEventListener("input", e => {
    debounce();
});



